import { PageProps } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import queryString, { ParsedQuery } from "query-string";


import * as utils from "~/utils";
import { fluidRange } from "~/styles";
import { SEO } from "~/components/layout/Seo";
import { CardPreviewContainer } from "~/components/common/CardPreviewContainer";
import ActionPillAgain from "~/components/customize/ActionPillAgain";


const StyledCont = styled.div`
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
`
const StyledCardPreviewContainer = styled(CardPreviewContainer)`
`
const StyledActionPillCont = styled.div`
  ${fluidRange('marginTop', '60px', '90px')};
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  
  > * {
    margin: auto;
  }
`

interface QueryData extends ParsedQuery<string> {
  target?: string;
}
interface CustomizeProps extends PageProps {}
const Customize: React.FC<CustomizeProps> = () => {

  let imageUrl: string = '';
  if( !utils.isSSR ) {
    const queryData: QueryData = queryString.parse(window.location.search) as QueryData;
    imageUrl = queryData.target || '';
  }
  

  return (
    <>
      <SEO />
      <StyledCont>
        <StyledCardPreviewContainer>
          <div>
            {
              imageUrl && 
              <img src={`${utils.getApiPublicPath()}${imageUrl}`} alt=""/>
            }
          </div>
        </StyledCardPreviewContainer>
        <StyledActionPillCont>
          <ActionPillAgain 
            header={ utils.t('Want to be kind as well?') }
            text={ utils.t('Create your own gentle card!') }
          />
        </StyledActionPillCont>
      </StyledCont>
    </>
  )
}
export default Customize;